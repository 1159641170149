<template>
	<div>
		<s-crud
			title="Temas de ayuda"
			:config="config"
			:filter="filter"
			add
			edit
			remove
			@save="save($event)"
		>
			<template scope="props">
				<v-col>
					<v-row>
						<v-col class="s-col-form">
							<s-text
								label="Tema"
								v-model="props.item.HpcSubject"
							></s-text>
						</v-col>
						<v-col class="s-col-form">
							<s-select-definition
								:def="1421"
								label="Tipo"
								v-model="props.item.TypeStatus"
							></s-select-definition>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="s-col-form">
							<s-textarea
								label="Descripción"
								v-model="props.item.HpcDescription"
							></s-textarea>
						</v-col>
					</v-row>
				</v-col>
			</template>

			<template v-slot:TypeStatus="{row}">
				<v-chip
					x-small
					:color="row.TypeStatusColor"
				>
					{{row.TypeStatusName}}
				</v-chip>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sHelpSubjectService from "@/services/HelpDesk/HdkHelpSubjectService.js";

	export default {
		data() {
			return {
				filter : {},
				config: {
					model : {
						HpcID : "ID",
						TypeStatus : "number",
						TypeStatusName : "string"
					},
					service : _sHelpSubjectService,
					headers : [
						{text : "ID", value : "HpcID"},
						{text : "Tema", value : "HpcSubject"},
						{text : "Descripción", value : "HpcDescription"},
						{text : "Tipo", value : "TypeStatus"},
					]
				},
			}
		},

		methods: {
			validationForm(item)
			{
				var errors = {
					errors : 0,
					message : ""
				}

				if(item.HpcSubject == "")
				{
					errors.errors = 1;
					errors.message = "Ingrese tema"
				}

				return errors;
			},

			save(item) {
				let errors = this.validationForm(item);
				if(errors.errors > 0)
				{
					this.$fun.alert(errors.message, "warning");
					return;
				}

				item.save();
			}
		},
	}
</script>
